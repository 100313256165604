import {
  Routes,
  Route,
  useLocation,
  Navigate,
} from "react-router-dom";
import './App.css';
import Login from "./login";
import Home from "./home";
import Pantalla from "./pantalla";
import PanelControl from "./administracion/panelcontrol";
import CrearLista from "./administracion/crear_lista";
import EditarLista from "./administracion/editar_lista";
import Trabajadores from "./administracion/admin_trabajadores";
import Test from "./test";
import EstilsRanking from "./administracion/estils_ranking";
import Iframe from "./iframe";
import Snapshots from "./administracion/snapshots";
import BottomNav from "./administracion/bottom_nav";


import { MobileView } from "react-device-detect";

function App() {
  let location = useLocation();

  return (
    <>
      <Routes key={location}>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Home />} />
        <Route path="/pantalla/:id" element={<Pantalla />} />
        <Route path="/dashboard" element={<PanelControl />} />
        <Route path="/dashboard/listas" element={<CrearLista />} />
        <Route path="/dashboard/lista/:id" element={<EditarLista />} />
        <Route path="/dashboard/trabajadores" element={<Trabajadores />} />
        <Route path="/dashboard/estilsranking" element={<EstilsRanking />} />
        <Route path="/dashboard/snapshots" element={<Snapshots />} />
        <Route path="/test" element={<Iframe />} />
      </Routes>
      
      <MobileView>
        <BottomNav />
      </MobileView>
    </>
  );
}

export default App;
