
import './login.css';
import socketIO from 'socket.io-client';
import { useState, useRef, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Login = () => {
    const email = useRef();
    const password = useRef();
    const socketRef = useRef(null);

    useEffect(() => {
       socketRef.current = socketIO.connect("https://pissarra.outlius.com", {
            'reconnection': true,
            'reconnectionDelay': 500,
            'reconnectionAttempts': 10,
            'transports': ['websocket'],
            'origins': '*:*'
          });

          socketRef.current.on('login', (data) => {
            console.log(data);
            if(data.code === 200){
                localStorage.setItem('token', data.token);
                window.location.href = '/dashboard';
            }else{
                toast.error("Error al hacer login", {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
        });


        return () => {
            socketRef.current.close();
      
          }
    }, []);




    const handleLogin = () => {


        socketRef.current.emit('login', {
            email: email.current.value,
            password: password.current.value
        });
     


    }





    return (

        <div className="screen-1">
            <img className="logo" src="/logo.png" alt="logo" />
            <div className="email">
                <label htmlFor="email">Email Address</label>
                <div className="sec-2">
                    <ion-icon name="mail-outline"></ion-icon>
                    <input ref={email} type="email" name="email" placeholder="Username@gmail.com" />
                </div>
            </div>
            <div className="password">
                <label htmlFor="password">Password</label>
                <div className="sec-2">
                    <ion-icon name="lock-closed-outline"></ion-icon>
                    <input ref={password} className="pas" type="password" name="password" placeholder="············" />
                    <ion-icon className="show-hide" name="eye-outline"></ion-icon>
                </div>
            </div>
            <button onClick={() => handleLogin()} className="login">Login</button>
            <div className="footer">En caso de error pongase en contacto con el administrador</div>
            <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>
    );
}
export default Login;