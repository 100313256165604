import React, { Component } from 'react'
import { useEffect, useState, useRef } from 'react';
import useInterval from './useInterval.js';
import { Transition, CSSTransition } from 'react-transition-group';

const Reunion = (props) => {

    const [listaTextos, setListaTextos] = useState([]);
    const [currentFrase, setCurrentFrase] = useState(0);
    const [animando, setAnimando] = useState(false);
    

    useEffect(() => {
        if (props.props) {
            setListaTextos(props.props.reunion.split(';'));
        }
    }, [props.props]);

    const changeFrase = () => {
        setAnimando(true);
        setTimeout(() => {
            setAnimando(false);
            setCurrentFrase((currentFrase + 1) % listaTextos.length);
        }, 300);
    }

    useInterval(changeFrase, 5000);


    const estilos = {
        bg: {
            //color: 'red',
            //BackgroundImage: `url(../${props.files})`,}
            //https://images.unsplash.com/photo-1568992687947-868a62a9f521?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1632&q=80


            //use image from url 
            backgroundImage: `url(https://images.unsplash.com/photo-1578070181910-f1e514afdd08?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1233&q=80)`,
            backgroundSize: 'cover',
            //oscurecer imagen
            backgroundBlendMode: 'multiply',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          //  backgroundColor: 'rgba(0,0,0,0.5)',
            color: 'white',

        },
        boxMain: {
            width: '70vw',
            height: '40vh',
            backgroundColor: 'white',
            margin: 'auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',

        },
        textoInside: {
            color: 'black',
            fontSize: '2.5em',
            textAlign: 'center',
            padding: '1em',
            fontWeight: 'bold',
        },
    }


    return (
        <div style={estilos.bg} className="fullPantalla">
            <div className="boxBlanca" style={estilos.boxMain}>
                <CSSTransition
                    in={currentFrase !== -1}
                    timeout={300}
                    classNames="fade"
                    unmountOnExit
                >
                    {
                        //dangerouslySetInnerHTML={{ __html: listaTextos[currentFrase] }}
                        //p with dangerouslySetInnerHTML={{ __html: listaTextos[currentFrase] }}

                        <p style={estilos.textoInside} className='fraseFooter' key={currentFrase}
                        dangerouslySetInnerHTML={{ __html: listaTextos[currentFrase] }}
                    />
                    }
            
                </CSSTransition>
            </div>
        </div>
    );
}
export default Reunion;