import { Fireworks } from '@fireworks-js/react'

const Premio = (props) => {
    //importa los props de la pantalla

    console.log(props);

    if (props.data.tipoPremio == 1) {
        const arrayBuffer = props.data.file.file;
        const blob = new Blob([arrayBuffer]);
        const videoURL = URL.createObjectURL(blob);
        
        return (
          <div className='boxPremio' style={{ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0, zIndex: -1 }}>
            <img src="https://pissarra.outlius.com/uploads/operadores/Nuria.png" className='imgPremioPers'/>
            <video autoPlay muted loop id="myVideo" style={{ width: '100%', height: '100%', objectFit: 'cover' }}>
              <source src={videoURL} type="video/mp4" />
            </video>
            <h1></h1>
          </div>
        );
    }
    

    return (
        <div className='boxPremioZ'>
            <div className='boxFelicidades'>
                <h1>{props.data.titulo}</h1>
                <h3>{props.data.subtitulo}</h3>
            </div>
            <Fireworks
                options={{
                    rocketsPoint: {
                        min: 0,
                        max: 100
                    }
                }}
                style={{
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    position: 'fixed',
                    background: '#000'
                }}
            />
        </div>
    )
}
export default Premio;  
