import React, { Component } from 'react'
import { useParams } from 'react-router-dom'
import { useEffect, useState, useRef } from 'react';
import './pantalla.css';
import socketIO from 'socket.io-client';

import Premio from './Premio';
import Reunion from './reunion';
import Ranking from './ranking';
import Iframe from './iframe';


const Home = () => {
  const socketRef = useRef(null);

  const { id } = useParams();
  const [frases, setFrases] = useState([]);

  const [dataPremio, setDataPremio] = useState(null);
  const [listaReproduccion, setListaReproduccion] = useState(null);
  const [showReunion, setShowReunion] = useState(false);
  const [showRanking, setShowRanking] = useState(true);
  const [showPremio, setShowPremio] = useState(false);
  const [reunionActual, setReunionActual] = useState(null);
  const [frasesRanking, setFrasesRanking] = useState([]);
  const [capaActual, setCapaActual] = useState(null);
  const [showGoogleDocs, setShowGoogleDocs] = useState(false);
  const [dataGoogleDocs, setDataGoogleDocs] = useState(null);
  const [googleDocsAuto, setGoogleDocsAuto] = useState(0);
  const [googleDocsPermante, setGoogleDocsPermante] = useState(true);


  useEffect(() => {
    if (!capaActual) return;

    changeView();
  }, [capaActual]);


  /*
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (googleDocsAuto === 1 && showGoogleDocs) {
        // Cambiar entre la pantalla actual y Google Docs
        setGoogleDocsPermante(prev => !prev);
      }
    }, 60000); // Intervalo de 1 minuto
  
    return () => clearInterval(intervalId); // Limpia el intervalo al desmontar el componente
  }, [googleDocsAuto, showGoogleDocs]);
  */
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (googleDocsAuto === 1 && showGoogleDocs) {
        // Cambiar entre la pantalla actual y Google Docs
        setGoogleDocsPermante((prev) => !prev);
      }
    }, googleDocsPermante ? 180000 : 1800000); // Intervalo de 1 minuto si es true, 3 minutos si es false
  
    return () => clearInterval(intervalId); // Limpia el intervalo al desmontar el componente
  }, [googleDocsAuto, showGoogleDocs, googleDocsPermante]);
  
  

  


  function changeView() {


    const currentLayer = capaActual;

    let tipo;
    if (currentLayer !== undefined && currentLayer !== null && currentLayer.tipo !== undefined) {
      tipo = currentLayer.tipo;
    } else {
      tipo = 1;
    }

    switch (tipo) {
      case 1:
        setFrasesRanking(currentLayer);
        setShowReunion(false);
        setShowRanking(true);
        break;
      case 2:

        setShowReunion(true);
        setReunionActual(currentLayer);
        setShowRanking(false);
        break;

      default:

        setShowReunion(false);
        setShowRanking(true);
        break;
    }

  }




  useEffect(() => {
    //http://localhost:8080
    //https://pissarra.outlius.com

   socketRef.current = socketIO.connect("https://pissarra.outlius.com", {
      'reconnection': true,
      'reconnectionDelay': 500,
      'reconnectionAttempts': 10,
      'transports': ['websocket'],
      'origins': '*:*'
    });


    //Envia el id de la pantalla para tener un control de las pantallas conectadas
    socketRef.current.emit('setPantallas', { id: id });



    //Envia el id de la pantalla para obtener la lista de reproduccion
    socketRef.current.emit('getListaAsignada', { id: id, type: "init" });
    /*
    socketRef.current.on('setPantallaLista', (data) => {
      setListaReproduccion(JSON.parse(data.res[0].json));
    });
*/

    socketRef.current.on('googleDocs', (data) => {
      if (data.id == id) {
        setDataGoogleDocs(data?.url?.urlGoogleDocs || null);
        setShowGoogleDocs(data.mostrarGoogle);
        setGoogleDocsAuto(data?.url?.auto || 0);
      
      }
    });

    socketRef.current.on('reiniciarPantalla', (data) => {

      if (!data.error) {
        if (data.success == id) {
          window.location.reload();
        }
      }

    });

    //CONTROLA SI LLEGA UN PREMIO
    socketRef.current.on('pantallaPremio', (data) => {
      if (data.id === id) {

        setDataPremio(data);
        setShowPremio(data.mostrarPremio);
      }
    });

    socketRef.current.on('enviando-capa', (data, idP) => {
      if (idP === id) {
        setCapaActual(data);
      }

    });

    socketRef.current.on('updateLista', (idP) => {
    
      if (idP === id) {
        socketRef.current.emit('getListaAsignada', { id: id, type: "update" });
      }
     
    });

    socketRef.current.on('listaGuardada', (data) => {
      socketRef.current.emit('getListaAsignada', { id: id, type: "update" });
    });


    // getOperadoras();
    return () => {
      socketRef.current.onclose = () => {
        socketRef.current.emit('disconnectPantalla', { id: id });
      };
      socketRef.current.close();

    }
  }, []);


  return (
    <>
      {showPremio ? <Premio data={dataPremio} /> :
        showGoogleDocs && googleDocsPermante ? <Iframe data={dataGoogleDocs} /> :
          showReunion ? <Reunion props={reunionActual} /> :
            showRanking ? <Ranking props={frasesRanking} /> : <Ranking />
      }

    </>

  );
};
export default Home;
