import React from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';



const Sidebar = () => {
    const location = useLocation();
    const path = location.pathname;
    

    return (
        <aside className="main-sidebar px-0 col-12 col-md-3 col-lg-2 sideBar">
            <div className="main-navbar">
                <nav className="align-items-stretch bg-white flex-md-nowrap border-bottom p-0 navbar navbar-light">
                    <span className="w-100 mr-0 navbar-brand" style={{ "lineHeight": "25px" }}>
                        <div className="d-table m-auto">
                            <img id="main-logo" className="d-inline-block align-top mr-1" src="./logowo1.png" alt="Shards Dashboard" style={{ "maxWidth": "25px" }} />
                            <span style={{ "fontSize": "15px", "fontWeight": "500", "marginLeft": "10px", "color": "#3d5170" }} className="d-none d-md-inline ml-1">Administracion Pantallas</span>
                        </div>
                    </span>
                </nav>
                <div class="nav-wrapper">
                    <ul class="nav--no-borders flex-column nav">
                        <li class="nav-item">
                            <Link style={{ "textDecoration": "none" }} to="/dashboard" >
                                <span class={path === "/dashboard" ? "nav-link active" : "nav-link"} aria-current="page" >
                                    <div class="d-inline-block item-icon-wrapper">
                                        <i class="material-icons">tv</i>
                                    </div>
                                    <span>Dashboard</span>
                                </span>
                            </Link>
                            <Link style={{ "textDecoration": "none" }} to="/dashboard/trabajadores" >
                                <span class={path === "/dashboard/trabajadores" ? "nav-link active" : "nav-link"} aria-current="page" >
                                    <div class="d-inline-block item-icon-wrapper">
                                        <i class="material-icons">group</i>
                                    </div>
                                    <span>Administrar trabajadores</span>
                                </span>
                            </Link>
                            <Link style={{ "textDecoration": "none" }} to="/dashboard/estilsranking" >
                                <span class={path === "/dashboard/estilsranking" ? "nav-link active" : "nav-link"} aria-current="page" >
                                    <div class="d-inline-block item-icon-wrapper">
                                        <i class="material-icons">view_list</i>
                                    </div>
                                    <span>Estilos ranking</span>
                                </span>
                            </Link>
                            <Link style={{ "textDecoration": "none" }} to="/dashboard/snapshots" >
                                <span class={path === "/dashboard/snapshots" ? "nav-link active" : "nav-link"} aria-current="page" >
                                    <div class="d-inline-block item-icon-wrapper">
                                        <i class="material-icons">photo_camera</i>
                                    </div>
                                    <span>Snapshots</span>
                                </span>
                            </Link>

                        </li>
                    </ul>
                </div>
            </div>
        </aside>
    )
}
export default Sidebar;