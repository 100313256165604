import { makeStyles } from '@material-ui/core/styles';
import { BottomNavigation, BottomNavigationAction } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PersonIcon from '@material-ui/icons/Person';
import FormatPaintIcon from '@material-ui/icons/FormatPaint'; // import the FormatPaintIcon component
import CameraIcon from '@material-ui/icons/Camera';
import { Link, useLocation } from 'react-router-dom';
import React from 'react';

const useStyles = makeStyles({
  root: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
    zIndex: 1000,
  },
});

const BottomNav = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const location = useLocation();
  
  if (!location.pathname.startsWith('/dashboard')) {
    return null;
  }

  return (
   <BottomNavigation
  value={value}
  onChange={(event, newValue) => {
    setValue(newValue);
  }}
  showLabels
  className={classes.root}
>
  <BottomNavigationAction label="Dashboard" icon={<DashboardIcon />} component={Link} to="/dashboard" />
  <BottomNavigationAction label="Trabajadores" icon={<PersonIcon />} component={Link} to="/dashboard/trabajadores" />
  <BottomNavigationAction label="Estilos" icon={<FormatPaintIcon />} component={Link} to="/dashboard/estilsranking" /> {/* replace the StyleIcon with the FormatPaintIcon */}
  <BottomNavigationAction label="Snapshots" icon={<CameraIcon />} component={Link} to="/dashboard/snapshots" />
</BottomNavigation>
  );
};

export default BottomNav;