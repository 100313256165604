
import OperadoraItem from './componentes_pantalla/operadora_item';
import socketIO from 'socket.io-client';
import { useParams } from 'react-router-dom';
import html2canvas from 'html2canvas';

import React, { useState, useEffect, useRef } from 'react';
import useInterval from './useInterval';
const Ranking = (props) => {
  
    const socketRef = useRef(null);
    const [operadoras, setOperadoras] = useState([]);
    const [frases, setFrases] = useState([]);
    const [total, setTotal] = useState(0);
    const [currentFrase, setCurrentFrase] = useState(0);
    const [cantidadOperadoras, setCantidadOperadoras] = useState(0);
    const [frasesMotivadoras, setFrasesMotivadoras] = useState([]);
    const [fraseObjetivo, setFraseObjetivo] = useState("");
    const [numeroObjetivo, setNumeroObjetivo] = useState(0);
    const [estilosRanking, setEstilosRanking] = useState(null);
    const audio = useRef(null);
    const { id } = useParams();
    const [logo, setLogo] = useState("");
    const [publi, setPubli] = useState("");
    const [gestion, setGestion] = useState("");
    const [sup, setSup] = useState(0);
    const [objetivos, setObjetivos] = useState(null);
    const [totalOperadorasKC, setTotalOperadorasKC] = useState(0);

    const eliminarCerosIzquierda = (numero) => {
        return parseInt(numero, 10);
    }

    useEffect(() => {
    
        if (props.props) {


            if (props.props.ranking) {
                setFrasesMotivadoras(props.props.ranking.split(';;'));
            }



        }
        socketRef.current = socketIO.connect("https://pissarra.outlius.com", {
            'reconnection': true,
            'reconnectionDelay': 500,
            'reconnectionAttempts': 10,
            'transports': ['websocket'],
            'origins': '*:*'
        });
        socketRef.current.emit('getEstilsRanking', { id: id });
        socketRef.current.emit('getOperadoras', { id: id });

        socketRef.current.on('estilsRankingActualitzats', (succes, ids) => {

            if (succes) {
                socketRef.current.emit('getEstilsRanking', { id: id });
            }

        });

        socketRef.current.on('setEstilsRanking', (data) => {


            if (data.estils[0].id_pantalla != id) { return }



            setEstilosRanking(data.estils[0]);


        });

        socketRef.current.on('estilsRankingActualitzats', (data) => {

        });
        socketRef.current.on('pantalla', (data, id_empresa, info_ranking, objetivos) => {

            if (id_empresa != id) { return }


            setObjetivos(objetivos);

            //busca en el array si de las operadoras una se llama publicidad
            setPubli(info_ranking.publi);
            setGestion(info_ranking.gestion);
            setOperadoras(data);

            //  audio.current.play();

            let total = 0;
            let totalEXP = 0;
            let totalsup = 0;
            let totalOperadoras = 0;
            let totalOperadorsKC = 0;

            data.forEach(element => {

                if (element.nombre.length > 0) {

                    if (element.K === 1 || element.C === 1) {
                    } else {
                        totalOperadoras++;
                    }
                }
                if (element.K === 1 || element.C === 1) {
                    totalOperadorsKC += element.exp;
                } else {
                    totalEXP += element.exp;
                    total += element.exp;
                    totalsup += element.sup;
                }
            });
            total = parseInt(total);
            totalEXP = parseInt(totalEXP);
            totalsup = parseInt(totalsup);
            totalOperadorsKC = parseInt(totalOperadorsKC);
            setTotalOperadorasKC(totalOperadorsKC);
            //num total exp sup / num total exp * 100.toFixed(1) +  %
            info_ranking.publi = parseInt(info_ranking.publi);

            let numPercent = ((totalsup + info_ranking.publiSup) / (totalEXP + info_ranking.publi) * 100).toFixed(0);
            if (numPercent === "NaN") {
                numPercent = 0;
            }
            setSup(numPercent + "%");

            let totalFinal = total + info_ranking.publi;
            setTotal(eliminarCerosIzquierda(totalFinal.toString()));
            setCantidadOperadoras(totalOperadoras);
        });





        socketRef.current.on('operadoras', (data, id_empresa, info_ranking, objetivos) => {
            if (id_empresa != id) { return }

            setObjetivos(objetivos);

            //busca en el array si de las operadoras una se llama publicidad
            setPubli(info_ranking[0].publi);
            setGestion(info_ranking[0].gestion);
            setOperadoras(data);

            //   audio.current.play();


            let totalEXP = 0;
            let total = 0;
            let totalsup = 0;
            let totalOperadoras = 0;
            let totalOperadorsKC = 0;

            data.forEach(element => {
                if (element.nombre.length > 0) {
                    if (element.K === 1 || element.C === 1) {

                    } else {
                        totalOperadoras++;
                    }
                }
                if (element.K === 1 || element.C === 1) {
                    totalOperadorsKC += element.exp;
                } else {
                    totalsup += element.sup;
                    totalEXP += element.exp;
                }
            });


            info_ranking[0].sup = parseInt(info_ranking[0].sup);


            totalEXP = parseInt(totalEXP);
            totalsup = parseInt(totalsup);
            totalOperadorsKC = parseInt(totalOperadorsKC);
            setTotalOperadorasKC(totalOperadorsKC);


            // totalsup = totalsup+ info_ranking[0].sup;
            //num total exp sup / num total exp * 100.toFixed(1) +  %



            let numPercent = ((totalsup + info_ranking[0].publi_sup) / (totalEXP + info_ranking[0].publi) * 100).toFixed(0);
            if (numPercent === "NaN") {
                numPercent = 0;
            }



            let totalPb = info_ranking[0].publi;
            totalPb = parseInt(totalPb);
            let totalFinal = totalEXP + info_ranking[0].publi;

            setSup(numPercent + "%");
            setTotal(eliminarCerosIzquierda(totalFinal.toString()));
            setCantidadOperadoras(totalOperadoras);
        });



        socketRef.current.on('file-uploaded-operadora', (data) => {
        });

        socketRef.current.on('capturarPantalla', (data) => {
            if (data.empresa != id) { return }
            html2canvas(document.body).then(canvas => {
                const imgData = canvas.toDataURL('image/png');
                socketRef.current.emit('capturarPantallaIMG', { imgData: imgData, id: id, usuario: data.usuario });
            });


        });


        return () => {
            socketRef.current.close();
        };
    }, [props]);

    const changeFrase = () => {
        setTimeout(() => {
            setCurrentFrase((currentFrase + 1) % frasesMotivadoras.length);
        }, 300);
    };

    useInterval(changeFrase, 5000);




    return (
        <div style={
            estilosRanking && estilosRanking.color_fons_imatge == 1
                ? { backgroundColor: estilosRanking.color_fons }
                : estilosRanking && estilosRanking.color_fons_imatge == 0
                    ? { backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundColor: "transparent", backgroundImage: `url(https://pissarra.outlius.com/uploads/${estilosRanking.imatge_fons})` }
                    : { backgroundColor: "white" }
        }
            className="mainBox"
            id="RankingMain"
        >
            <div className="mainBoxHeader">
                <img className='logoFooter' src={estilosRanking && "https://pissarra.outlius.com/uploads/" + estilosRanking.logo} alt="imagen" />
                <audio src='https://pissarra.outlius.com/uploads/songs/ranking.mp3' ref={audio}></audio>
            </div>
            <div className="mainBoxBody" id={"opBox"+id}>
                <div className='header'>
                    <div className='mesActual'>
                        {
                            //muestra objetivos.mes si no es null si es null muestra el mes actual
                            objetivos && objetivos.mes && objetivos.mes
                            //new Date().toLocaleString('default', { month: 'long' })
                        }
                    </div>
                    <div className="mainItemHeader" >
                        <div className=''>

                        </div>
                        <div className="itemHeader">
                            <p></p>
                            <h2></h2>
                            <p className='Hexp'>EXP</p>
                            <p className='Hsem'>SEM</p>
                            <p className='Hsup'>SUP</p>
                            <p className='Ht'>T</p>
                        </div>
                    </div>
                    <div className="mainItemHeader" >
                        <div className=''>

                        </div>
                        <div className="itemHeader">
                            <p></p>
                            <h2></h2>
                            <p className='Hexp'>EXP</p>
                            <p className='Hsem'>SEM</p>
                            <p className='Hsup'>SUP</p>
                            <p className='Ht'>T</p>
                        </div>
                    </div>


                </div>
                <div className='supSplitTwo'>
                    <div className='splitTwo'>

                        {operadoras &&
                            operadoras.slice().sort((a, b) => {
                                // Si alguno de los nombres es vacío, muévelo al final
                                if (a.nombre === "" && b.nombre !== "") {
                                    return 1;
                                }
                                if (a.nombre !== "" && b.nombre === "") {
                                    return -1;
                                }

                                // Ordena por exp
                                if (a.exp < b.exp) {
                                    return 1;
                                }
                                if (a.exp > b.exp) {
                                    return -1;
                                }

                                // ordena por t
                                if (a.t < b.t) {
                                    return 1;
                                }
                                if (a.t > b.t) {
                                    return -1;
                                }
                                // ordena por sem
                                if (a.sem < b.sem) {
                                    return 1;
                                }
                                if (a.sem > b.sem) {
                                    return -1;
                                }

                                // y por ultimo por nombre
                                const nameA = a.nombre.toUpperCase();
                                const nameB = b.nombre.toUpperCase();

                                if (nameA < nameB) {
                                    return -1;
                                }
                                if (nameA > nameB) {
                                    return 1;
                                }

                                // Si el nombre también es el mismo, ordena por posición en el arreglo
                                return 0;
                            }).map((operadora, i) => {
                                const isLeft = (i < 9);
                                const row = isLeft ? i + 1 : i - 8;
                                let bgColor = "#f5f5f5";
                                if (i == 1 || i == 2 || i == 0) {
                                    bgColor = "#50eca5";
                                }
                                if (operadora.K === 1) {
                                    bgColor = "#e47911";
                                }
                                if (operadora.C === 1) {
                                    bgColor = "#bb69e8";
                                }
                                let img = "";
                                if (operadora.img) {
                                    img = "https://pissarra.outlius.com/uploads/operadores/" + operadora.img;
                                } else {
                                    img = "https://pissarra.outlius.com/uploads/operadores/default.png";
                                }

                                return (
                                    <div className={isLeft ? "left" : "right"} style={{ gridRow: row, gridColumn: isLeft ? 1 : 2 }}>
                                        <OperadoraItem contador={i + 1}
                                            key={i}
                                            img={img}
                                            nombre={operadora.nombre}
                                            exp={operadora.exp}
                                            sem={operadora.sem}
                                            sup={operadora.sup}
                                            T={operadora.t + operadora.exp}
                                            color={bgColor}

                                        />
                                    </div>
                                )
                            })}
                    </div>
                    <div className='divDos'>
                        <div>{objetivos && objetivos.cantidad > 0 &&
                            <div className='padreObj'>
                                <div className='objetivoDiv'>
                                    {objetivos && objetivos.frase}
                                </div>
                                {
                                    objetivos && objetivos.todos_objetivo == 0 &&
                                    <span className='objeNum'>{objetivos && objetivos.tipo == 0 ? objetivos.cantidad : objetivos.cantidad - total}</span>
                                }
                                {
                                    objetivos && objetivos.todos_objetivo == 1 &&
                                    <span className='objeNum'>{objetivos && objetivos.tipo == 0 ? objetivos.cantidad : objetivos.cantidad - (total + totalOperadorasKC)}</span>
                                }

                            </div>}</div>
                        <div className='divrdos'>
                            <div className='padreObj'><div className='objetivoDivV2'>publi {publi && publi}</div></div>
                            <div className='padreObj'><div className='objetivoDivV2'>gestion {gestion && gestion}</div></div>
                            <div className='padreObj'><div className='objetivoDivV2'>sup {sup}</div></div>
                            <div className='padreObj'>
                                <div className='objetivoDivV'>
                                    TOTAL
                                </div>
                                <span className='objeNum'>{total}</span>
                            </div>
                        </div>
                    </div>


                </div>

            </div>
            <div className='footer'>
                {
                    frasesMotivadoras &&

                    <p key={currentFrase} style={estilosRanking && estilosRanking.text_color && { color: estilosRanking.text_color }} className='fraseFooter'
                        dangerouslySetInnerHTML={{ __html: frasesMotivadoras[currentFrase] }}
                    />
                }
                {

                    //<p key={currentFrase} style={estilosRanking && estilosRanking.text_color && { color: estilosRanking.text_color }} className='fraseFooter'>{frasesMotivadoras && frasesMotivadoras[currentFrase]}</p>

                }
            </div>
        </div>

    )

}
export default Ranking