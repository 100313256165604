import React from 'react';
import { useEffect, useState, useRef } from 'react';
import '../index.css';
import socketIO from 'socket.io-client';
import Sidebar from './sidebar';
import TopBar from './topbar';
import { FileUploader } from "react-drag-drop-files";
import { BrowserView } from 'react-device-detect';



import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AdministracionTrabajadores = () => {
    const socketRef = useRef(null);
    const fileTypes = ["PNG", "GIF", "JPG", "JPEG"];



    const handleChange = (file) => {
        console.log(file);
        //setFile(file.target.files[0]);
        let fileData = {
            name: file.name,
            file: file,
        }
        setFile(fileData);



    };

    const guardarEnviar = () => {

        socketRef.current.emit('trabajadores',
            JSON.stringify({
                trabajadores: trabajadores,
            }),
            localStorage.getItem('token'), selectedEmpresa, { publi: publi, gestion: gestion, publiSup: publiSup, }, objetivoData);

        toast.success("Enviado correctamente", {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });






    };


    const [file, setFile] = useState(null);
    const [empresas, setEmpresas] = useState([]);
    const [selectedEmpresa, setSelectedEmpresa] = useState(null);
    const [trabajadores, setTrabajadores] = useState([]);
    const [popup, setPopup] = useState(false);
    const [selectedTrabajador, setSelectedTrabajador] = useState(null);
    const [publi, setPubli] = useState(null);
    const [gestion, setGestion] = useState(null);
    const [triggerRender, setTriggerRender] = useState(false);
    const [ordenNombre, setOrdenNombre] = useState('asc');
    const [ordenExp, setOrdenExp] = useState('asc');
    const [ordenSem, setOrdenSem] = useState('asc');
    const [ordenSup, setOrdenSup] = useState('asc');
    const [ordenT, setOrdenT] = useState('asc');
    const [modeDepuracio, setModeDepuracio] = useState("");
    const [publiSup, setPubliSup] = useState(null);
    const [objetivoData, setObjetivoData] = useState(null);
    const [campanyaData, setCampanyaData] = useState(null);



    useEffect(() => {



        socketRef.current = socketIO.connect("https://pissarra.outlius.com", {
            'reconnection': true,
            'reconnectionDelay': 500,
            'reconnectionAttempts': 10,
            'transports': ['websocket'],
            'origins': '*:*'
        });

        handleCambioEmpresa()
        getEmpresas();

        socketRef.current.on('file-uploaded-operadora', (response) => {
            console.log(response);
            if (response.success) {
                toast.success("Imagen subida correctamente", {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setPopup(false);
                getTrabajadores();

            }

        });

        socketRef.current.on('error-auth', (data) => {
            toast.error("Error de autenticación", {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                onClose: () => {
                    localStorage.removeItem('token');
                    window.location.href = '/login';
                }
            });

        });

        socketRef.current.on('resetearSem', async (data) => {
            if (data.error) {
                toast.error("Error al resetear la semana", {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } else {
                toast.success("Semana reseteada correctamente", {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                await getTrabajadores();
                guardarEnviar();
            }
        });


        // socket.on('pantalla', (data) => {
        socketRef.current.on('trabajadores', (data) => {
        });

        socketRef.current.on('empresas', (data) => {
            setEmpresas(data.empresas);
        });

        socketRef.current.on('gettrabajadores', (data, infoRanking, token) => {

            if (token === localStorage.getItem('token')) {

                setPubli(infoRanking[0].publi);
                setGestion(infoRanking[0].gestion);
                setPubliSup(infoRanking[0].publi_sup);
                setTrabajadores(data);
            }



        });

        socketRef.current.on('trabajadorEliminado', async (empresa) => {
            if (empresa === selectedEmpresa) {
                await getTrabajadores();
                guardarEnviar();
            }
        });
        socketRef.current.on('resetearMes', async (empresa, mensaje) => {
            if (empresa === selectedEmpresa) {
                getTrabajadores();
                socketRef.current.emit('getOperadoras', { id: empresa });
            }
        });

        socketRef.current.on('capturarPantallaIMG', (data) => {
          
            if (data.usuario === socketRef.current.id) {

                downloadImage(data.imgData, 'captura-de-pantalla' + data.empresa + '.png');

            }
        });

        socketRef.current.on('setObjetivos', (data) => {
            if (data.id_empresa === selectedEmpresa) {
                setObjetivoData(data);
            }
        });




        return () => {
            setObjetivoData(null);
            socketRef.current.close();
        }
    }, [selectedEmpresa]);


    const capturar = () => {
        socketRef.current.emit('capturar', { empresa: selectedEmpresa, usuario: socketRef.current.id });
    }
    const downloadImage = (dataUrl, filename) => {
        const link = document.createElement('a');
        link.href = dataUrl;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const handleCambioEmpresa = () => {
        if (!selectedEmpresa) {
            return;
        }
        getTrabajadores();
        getObjetivos();
    }

    const getObjetivos = () => {
        socketRef.current.emit('getObjetivos', { empresa: selectedEmpresa });
    }



    const getTrabajadores = () => {
        return new Promise((resolve, reject) => {
            let data = {
                token: localStorage.getItem('token'),
                empresa: selectedEmpresa,
                usuario: socketRef.current.id
            };
            socketRef.current.emit('gettrabajadores', data, () => {
                resolve();
            });
        });

    }

    const guardarAvatar = () => {
        let data = {
            file: file,
            trabajador: selectedTrabajador,
        }
        socketRef.current.emit('upload-file-operadora', data);
    }

    const changePubli = (e) => {
        setPubli(e.target.value);
    }
    const changePubliSup = (e) => {
        setPubliSup(parseInt(e.target.value));
    }


    const changeGestion = (e) => {
        setGestion(e.target.value);
    }

    const getEmpresas = () => {
        socketRef.current.emit('empresas', localStorage.getItem('token'));
    }

    const ordenarPorNombre = (type) => {
        if (type === 'asc') {
            setTrabajadores(trabajadores.sort((a, b) => (a.nombre > b.nombre) ? 1 : -1));
            setOrdenNombre('desc');
        } else {
            setTrabajadores(trabajadores.sort((a, b) => (a.nombre < b.nombre) ? 1 : -1));
            setOrdenNombre('asc');
        }

    }

    const ordenarPorExp = (type) => {
        if (type === 'asc') {
            setTrabajadores(trabajadores.sort((a, b) => (a.exp > b.exp) ? 1 : -1));
            setOrdenExp('desc');
        } else {
            setTrabajadores(trabajadores.sort((a, b) => (a.exp < b.exp) ? 1 : -1));
            setOrdenExp('asc');
        }

    }

    const ordenarPorSem = (type) => {
        if (type === 'asc') {
            setTrabajadores(trabajadores.sort((a, b) => (a.sem > b.sem) ? 1 : -1));
            setOrdenSem('desc');
        } else {
            setTrabajadores(trabajadores.sort((a, b) => (a.sem < b.sem) ? 1 : -1));
            setOrdenSem('asc');
        }
    }
    const ordenarPorSup = (type) => {
        if (type === 'asc') {
            setTrabajadores(trabajadores.sort((a, b) => (a.sup > b.sup) ? 1 : -1));
            setOrdenSup('desc');
        } else {
            setTrabajadores(trabajadores.sort((a, b) => (a.sup < b.sup) ? 1 : -1));
            setOrdenSup('asc');
        }
    }
    const ordenarPorT = (type) => {
        if (type === 'asc') {
            setTrabajadores(trabajadores.sort((a, b) => (a.t > b.t) ? 1 : -1));
            setOrdenT('desc');
        } else {
            setTrabajadores(trabajadores.sort((a, b) => (a.t < b.t) ? 1 : -1));
            setOrdenT('asc');
        }
    }


    const resetejarMes = async () => {
        if (!window.confirm('Estas seguro que quieres reiniciar el més?')) {
            return;
        }
        socketRef.current.emit('resetearMes', trabajadores, selectedEmpresa, 0);
    }
    const resetejarTrimestral = async () => {

        if (campanyaData === null || campanyaData === "") {
            if (!window.confirm('Estas seguro que quieres reiniciar el trimestre sense guardar la campanya?')) {
                return;
            }
        } else {
            if (!window.confirm('Estas seguro que quieres reiniciar el trimestre?')) {
                return;
            }
        }
        socketRef.current.emit('resetearMes', trabajadores, selectedEmpresa, 1, campanyaData);

    }

const resetejarSemana = async () => {
    if (!window.confirm('Estas seguro que quieres reiniciar la semana?')) {
        return;
    }
    socketRef.current.emit('resetearSem', trabajadores, selectedEmpresa, 2);

}



    const deleteTrabajador = (id,) => {
        if (!window.confirm('Estas seguro que quieres elimina la operadora?')) {
            return;
        }

        //hace que el trabajador tenga todos los campos en 0, que se le borre la foto y que se le ponga el avatar por defecto y se le borre el nombre
        socketRef.current.emit('deleteTrabajador', id, selectedEmpresa);

    }







    return (
        <div style={{ "min-width": "100vw", height: "100vh" }}>
            <BrowserView>
                <Sidebar />
            </BrowserView>

            <div className='main-content p-0 col-12 col-md-9 col-lg-10 offset-md-3 offset-lg-2'>
                <BrowserView>
                    <TopBar />
                </BrowserView>
                <div class="page-header py-4 no-gutters row">
                    <div className="text-sm-left mb-3 text-md-left mb-sm-0 col-12 col-sm-4 fw" style={{ maxWidth: '100vw' }}>
                        <span className="text-uppercase page-subtitle">ADMINISTRACIÓN</span>
                        <h3 className="page-title">Administración de los trabajadores</h3>
                        <br></br>
                        <div className='boxEmpresas sepT'>
                            {
                                empresas.map((empresa) => {
                                    return (
                                        <div
                                            onClick={() => {
                                                setSelectedEmpresa(empresa.id);
                                            }}
                                            style={{

                                            }} className='boxEmpresa '>
                                            <p className='empresasNombre'>{empresa.nombre}</p>
                                            <p className='empresasTrabajadores'>{empresa.cantidad}</p>
                                            <p className='empresasNombreT'>trabajadores</p>
                                        </div>
                                    )
                                }
                                )}
                        </div>
                        {trabajadores.length > 0 && <>

                            <div className='boxEmpresaGeneral'>
                                <div className='boxEmpresas2'>
                                    <div className='boxEmpresa'>
                                        <p className='empresasNombre'>PUBLI</p>
                                        <p className='empresasTrabajadores'><input className='centerINP' onChange={(e) => changePubli(e)} value={publi} ></input></p>
                                        <div className='divSupPubli'>
                                            <span>Sup: </span>
                                            <input className='centerINP publiSup' onChange={(e) => changePubliSup(e)} value={publiSup} ></input>
                                        </div>

                                    </div>
                                    <div className='boxEmpresa'>
                                        <p className='empresasNombre'>Gestion</p>
                                        <p className='empresasTrabajadores'><input className='centerINP' onChange={(e) => changeGestion(e)} value={gestion}></input></p>
                                    </div>
                                    <div className='boxEmpresa'>
                                        <p className='empresasNombre'>SCREENSHOT</p>
                                        <br></br>
                                        <button className='btn btn-main' onClick={capturar}>CAPTURAR PANTALLA</button>
                                    </div>
                                    <div className='objetivoBoxAdmin'>
                                        {objetivoData && <>
                                            <p className='empresasNombre'>OBJETIVO</p>
                                            <div>
                                                <p className='empresasNombreT'>Escribe aquí la frase objetivo</p>
                                                <input className='centerINP' value={objetivoData.frase}
                                                    onChange={(e) => setObjetivoData({ ...objetivoData, frase: e.target.value })}
                                                ></input>
                                            </div>
                                            <div className='centerGridObj' >
                                                <div>
                                                    <p className='empresasNombreT'>Escribe aquí el num objetivo</p>
                                                    <input className='centerINP' type="number" value={objetivoData.cantidad}
                                                        onChange={(e) => setObjetivoData({ ...objetivoData, cantidad: e.target.value })}
                                                    ></input>

                                                </div>
                                                <div>
                                                    <p className='empresasNombreT tcenter'>Este es un contador automático</p>
                                                    <div className=" flexCenter form-check form-switch">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={objetivoData.tipo}
                                                            onChange={(e) => setObjetivoData({ ...objetivoData, tipo: e.target.checked })}
                                                        />
                                                    </div>

                                                </div>
                                                
                                                <div>
                                                    <p className='empresasNombreT'>Escribe el mes que quieras mostrar</p>
                                                    <input className='centerINP' value={objetivoData.mes}
                                                        onChange={(e) => setObjetivoData({ ...objetivoData, mes: e.target.value })}
                                                    ></input>

                                                </div>
                                                <div>
                                                    <p className='empresasNombreT tcenter'
                                                        style={{ "fontSize": "9px" }}
                                                    >Añadir todos los trabajadores al objetivo</p>
                                                    <div className=" flexCenter form-check form-switch">
                                                        <input
                                                            className="form-check-input"
                                                        
                                                            type="checkbox"
                                                            checked={objetivoData.todos_objetivo}
                                                            onChange={(e) => setObjetivoData({ ...objetivoData, todos_objetivo: e.target.checked })}
                                                          
                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                        </>}
                                    </div>
                                </div>

                            </div>



                            <div className='boxTrabajadores'>
                                <h2 className='tituloGeneral'>Trabajadores {selectedEmpresa == 1 ? "integram" : selectedEmpresa == 2 ? "kram" : selectedEmpresa == 3 ? "jqs" : "DEV"}</h2>
                                <div class="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>NOMBRE <span onClick={() => ordenarPorNombre(ordenNombre)}>{ordenNombre === 'asc' ? <>▲</> : <>▼</>}</span></th>
                                                <th>Exp.  <span onClick={() => ordenarPorExp(ordenExp)}>{ordenExp === 'asc' ? <>▲</> : <>▼</>}</span></th>
                                                <th>Sem.* <span onClick={() => ordenarPorSem(ordenSem)}>{ordenSem === 'asc' ? <>▲</> : <>▼</>}</span></th>
                                                <th>Sup. <span onClick={() => ordenarPorSup(ordenSup)}>{ordenSup === 'asc' ? <>▲</> : <>▼</>}</span></th>
                                                <th>T <span onClick={() => ordenarPorT(ordenT)}>{ordenT === 'asc' ? <>▲</> : <>▼</>}</span></th>
                                                <th>img</th>
                                                <th>Eliminar</th>
                                                <th>Kram</th>
                                                <th>Cobrofy</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {trabajadores.map((trabajador) => {
                                                return (
                                                    <tr key={trabajador.id}>
                                                        <td>
                                                            <input value={trabajador.nombre} onChange={(event) => {
                                                                const newTrabajadores = trabajadores.map((t) => {
                                                                    if (t.id === trabajador.id) {
                                                                        return { ...t, nombre: event.target.value };
                                                                    }
                                                                    return t;
                                                                });
                                                                setTrabajadores(newTrabajadores);
                                                            }} />
                                                        </td>
                                                        <td>
                                                            <input type="number" value={trabajador.exp} onChange={(event) => {
                                                                const newTrabajadores = trabajadores.map((t) => {
                                                                    if (t.id === trabajador.id) {
                                                                        return { ...t, exp: event.target.value };
                                                                    }
                                                                    return t;
                                                                });
                                                                setTrabajadores(newTrabajadores);
                                                            }} />
                                                        </td>
                                                        <td>
                                                            <input type="number" value={trabajador.sem} onChange={(event) => {
                                                                const newTrabajadores = trabajadores.map((t) => {
                                                                    if (t.id === trabajador.id) {
                                                                        return { ...t, sem: event.target.value };
                                                                    }
                                                                    return t;
                                                                });
                                                                setTrabajadores(newTrabajadores);
                                                            }} />
                                                        </td>
                                                        <td>
                                                            <input type="number" value={trabajador.sup} onChange={(event) => {
                                                                const newTrabajadores = trabajadores.map((t) => {
                                                                    if (t.id === trabajador.id) {
                                                                        return { ...t, sup: event.target.value };
                                                                    }
                                                                    return t;
                                                                });
                                                                setTrabajadores(newTrabajadores);
                                                            }} />
                                                        </td>

                                                        {
                                                            modeDepuracio == 3333 &&
                                                            <td>
                                                                <input type="number" value={trabajador.t} onChange={(event) => {
                                                                    const newTrabajadores = trabajadores.map((t) => {
                                                                        if (t.id === trabajador.id) {
                                                                            return { ...t, t: event.target.value };
                                                                        }
                                                                        return t;
                                                                    });
                                                                    setTrabajadores(newTrabajadores);
                                                                }} />
                                                            </td>

                                                        }
                                                        {
                                                            modeDepuracio != 3333 &&
                                                            <td>
                                                                <input type="number" value={trabajador.t} readOnly />
                                                            </td>
                                                        }



                                                        <td>
                                                            <div onClick={() => { setSelectedTrabajador(trabajador); setPopup(true) }} className='boxAvatar'>
                                                                <div className='editIcon'>
                                                                    <span className="material-icons">
                                                                        edit
                                                                    </span>
                                                                </div>
                                                                <img className='avatar' src={'https://pissarra.outlius.com/uploads/operadores/' + trabajador.img} />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className='delIcon' onClick={() => { deleteTrabajador(trabajador.id) }}>
                                                                <span className="material-icons">
                                                                    delete
                                                                </span>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className=" flexCenter form-check form-switch">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    onChange={
                                                                        () => {
                                                                            const newTrabajadores = trabajadores.map((t) => {
                                                                                if (t.id === trabajador.id) {
                                                                                    return { ...t, K: !t.K };
                                                                                }
                                                                                return t;
                                                                            });
                                                                            setTrabajadores(newTrabajadores);
                                                                        }
                                                                    }


                                                                    checked={trabajador.K}
                                                                />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className=" flexCenter form-check form-switch">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    onChange={
                                                                        () => {
                                                                            const newTrabajadores = trabajadores.map((t) => {
                                                                                if (t.id === trabajador.id) {
                                                                                    return { ...t, C: !t.C };
                                                                                }
                                                                                return t;
                                                                            });
                                                                            setTrabajadores(newTrabajadores);
                                                                        }
                                                                    }


                                                                    checked={trabajador.C}
                                                                />
                                                            </div>
                                                        </td>

                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                                {popup && <div className='popup'>
                                    <div className='bgPop'>
                                        <div className='popup itemClicked'>
                                            <span className='closePop' onClick={() => setPopup(false)}>X</span>

                                            <div className='popupContent'>
                                                <div className='popupHeader'>
                                                    <h2 className='text-uppercase page-subtitle'></h2>
                                                    <h3 className="page-title">Añadir nueva capa </h3>
                                                    <p className='minTxt'><em>Añade o modifica el avatar de: {selectedTrabajador.nombre}</em> </p>
                                                </div>
                                                <div className='popupBody1'>
                                                    <div>
                                                        <FileUploader handleChange={handleChange} name="file" types={fileTypes} />
                                                    </div>
                                                </div>
                                                <div className='popupBody2'>
                                                    <button onClick={guardarAvatar} className='btn btn-main mt10'>Guardar</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                }

                                <div className="responsive-container">
                                    <button onClick={guardarEnviar} className='btn btn-main'>Guardar y enviar a la pantalla</button>
                                    <input placeholder='Mode depuració' className="depuracio" onChange={(e) => setModeDepuracio(e.target.value)} value={modeDepuracio}></input>
                                    <span className='midSup'>Sup:
                                        {
                                            (((trabajadores.filter(t => t.K !== 1 && t.C !== 1).reduce((a, b) => a + parseInt(b.sup), 0) + publiSup) / (trabajadores.filter(t => t.K !== 1 && t.C !== 1).reduce((a, b) => a + parseInt(b.exp), 0) + parseInt(publi))) * 100).toFixed(0) + "%"
                                        }
                                    </span>
                                    <span className='midSup'>Total:
                                        {
                                            (trabajadores
                                                .filter(trabajador => trabajador.K !== 1 && trabajador.C !== 1)
                                                .reduce((total, trabajador) => total + parseInt(trabajador.exp), 0)) + parseInt(publi)
                                        }
                                    </span>
                                    
                                    <button className='btn btn-main btnTrim' onClick={resetejarTrimestral}>Reset trimestral</button>
                                    <button className='btn btn-main btnMes' onClick={resetejarMes}>Reset mes</button>
                                    <button className='btn btn-main' onClick={resetejarSemana} style={{ "backgroundColor": "blue!important", "float": "right", "marginRight": "10px" }}>
                                        Reset semanal</button>
                                    <input className="depuracio campanya" placeholder='Campaña' onChange={(e) => setCampanyaData(e.target.value)} value={campanyaData}></input>
                                </div>
                            </div>

                        </>
                        }
                        <ToastContainer
                            position="bottom-center"
                            autoClose={5000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                            theme="light"
                        />
                    </div>
                </div>
            </div>
        </div>

    )
}
export default AdministracionTrabajadores;