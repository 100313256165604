import React from 'react';
import { useEffect, useState, useRef } from 'react';
import '../index.css';
import socketIO from 'socket.io-client';
import Sidebar from './sidebar';
import TopBar from './topbar';
import { FileUploader } from "react-drag-drop-files";
import { BrowserView } from 'react-device-detect';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const EstilsRanking = () => {
    const socketRef = useRef(null);
    const fileTypes = ["PNG", "GIF", "JPG", "JPEG"];
    const [pantallas, setPantallas] = useState([]);
    const [selectedPantalla, setSelectedPantalla] = useState(null);
    const [estilsPantalla, setEstilsPantalla] = useState(null);
    const [logoFile, setLogoFile] = useState(null);
    const [imatgeFonsFile, setImatgeFonsFile] = useState(null);


    const empresas = {
        1: "Integram",
        2: "Kram",
        3: "JQS",
    };

    useEffect(() => {

        socketRef.current = socketIO.connect("https://pissarra.outlius.com", {
            'reconnection': true,
            'reconnectionDelay': 500,
            'reconnectionAttempts': 10,
            'transports': ['websocket'],
            'origins': '*:*'
        });

        socketRef.current.emit('getTotalPantallas');

        socketRef.current.on('setEstilsRanking', (data) => {
            if (data.token === localStorage.getItem('token')) {

                setEstilsPantalla(data.estils[0]);
                setSelectedPantalla(data.estils[0].id_pantalla);
            }


        });

        socketRef.current.on('totalPantallas', (data) => {
            setPantallas(data.totalPantallas);
        });
        socketRef.current.on('file-uploaded-ranking', (data) => {
            if (data.success == false) {
                toast.error("Error al subir el archivo");
            }
        });

        socketRef.current.on('estilsRankingActualitzats', (data) => {

            if (data.token === localStorage.getItem('token')) {
                if (data.success == false) {
                    toast.error("Error al guardar los estilos");
                }
                if (data.success == true) {
                    toast.success("Estilos guardados correctamente");
                }
            }
        });


        return () => {
            socketRef.current.close();
        }
    }, [selectedPantalla]);

    const handleCambioPantalla = (id) => {
        socketRef.current.emit('getEstilsRanking', { id: id }, localStorage.getItem('token'));

    }
    const changeColoroImatge = (e) => {
        setEstilsPantalla({ ...estilsPantalla, color_fons_imatge: e.target.value });
    }
    const handleTextColorChange = (e) => {
        const newColor = e.target.value;
        setEstilsPantalla({ ...estilsPantalla, text_color: newColor });
    };
    const handleFonsColorChange = (e) => {
        const newColor = e.target.value;
        setEstilsPantalla({ ...estilsPantalla, color_fons: newColor });
    };

    const handleChangeLogo = (file) => {
        let fileData = {
            name: file.name,
            file: file,
        }
        setLogoFile(fileData);

        setEstilsPantalla({ ...estilsPantalla, logo: file.name });

    }

    const handleChangeImatgeFons = (file) => {
        let fileData = {
            name: file.name,
            file: file,
        }
        setImatgeFonsFile(fileData);

        setEstilsPantalla({ ...estilsPantalla, imatge_fons: file.name });
    }

    const guardarEnviar = () => {


        socketRef.current.emit('updateEstilsRanking', { estilsPantalla: estilsPantalla, logoFile: logoFile, imatgeFonsFile: imatgeFonsFile, token: localStorage.getItem('token') });


    }

    return (
        <>
            <div style={{ "min-width": "100vw", "min-height": "100%" }}>
                <BrowserView>
                    <Sidebar />
                </BrowserView>

                <div className='main-content p-0 col-sm-12 col-md-9 offset-md-3 col-lg-10 offset-lg-2'>
                    <BrowserView>
                        <TopBar />
                    </BrowserView>

                    <div class="page-header py-4 no-gutters row">
                        <div className="text-sm-left mb-3 text-md-left mb-sm-0 col-12 col-sm-4 fw">
                            <span className="text-uppercase page-subtitle">ADMINISTRACIÓN</span>
                            <h3 className="page-title">Estilos ranking</h3>
                            <br></br>
                            <div className='boxEmpresas'>
                                {pantallas &&

                                    pantallas.map((pantalla) => {
                                        return (
                                            <div
                                                onClick={() => {
                                                    handleCambioPantalla(pantalla.id);
                                                }}
                                                style={{

                                                }} className='boxEmpresa '>
                                                <p className='empresasNombre'>{empresas[pantalla.id_empresa]}</p>
                                                <p className='empresasTrabajadores'>{pantalla.id}</p>
                                                <p className='empresasNombreT'>PANTALLA</p>
                                            </div>
                                        )
                                    }
                                    )

                                }
                            </div>
                            {selectedPantalla && estilsPantalla &&
                                <div className='boxTrabajadores'>
                                    <div className='boxTrabajadores'>
                                        <h2 className='tituloGeneral'>ESTILOS RANKING PANTALLA {selectedPantalla}</h2>
                                        <div class="table-responsive">
                                            <table className="table tbR">
                                                <thead>
                                                    <tr>
                                                        <th>color de fondo o imagen</th>
                                                        <th>Logo</th>
                                                        <th>{estilsPantalla.color_fons_imatge == 0 ? "Imatge de Fons" : "Color de Fons"}</th>
                                                        <th>Color text</th>


                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <select className="form-control" onChange={(e) => changeColoroImatge(e)} value={estilsPantalla.color_fons_imatge} name="color_fons_imatge">
                                                                <option value={1}> Color</option>
                                                                <option value={0}> Imatge</option>
                                                            </select>

                                                        </td>
                                                        <td>
                                                            <FileUploader
                                                                fileTypes={fileTypes}
                                                                handleChange={handleChangeLogo}
                                                            />
                                                            <span className='smallSpan'>Imagen: '<em>{estilsPantalla.logo}</em>'</span>
                                                        </td>
                                                        <td>
                                                            {

                                                                estilsPantalla.color_fons_imatge == 1 ?
                                                                    <input type="color" name="favcolor" value={estilsPantalla.color_fons} onChange={(e) => handleFonsColorChange(e)}></input>
                                                                    :
                                                                    <>
                                                                        <FileUploader
                                                                            fileTypes={fileTypes}
                                                                            handleChange={handleChangeImatgeFons}
                                                                        />
                                                                        <span className='smallSpan'>Imagen: '<em>{estilsPantalla.imatge_fons}</em>'</span>
                                                                    </>
                                                            }
                                                        </td>
                                                        <td>
                                                            <input type="color" name="favcolor" value={estilsPantalla.text_color} onChange={(e) => handleTextColorChange(e)} ></input>
                                                        </td>
                                                    </tr>


                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <br></br>
                                    <button onClick={guardarEnviar} className='btn btn-main'>Guardar y enviar a la pantalla</button>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <ToastContainer
                    position="bottom-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />
            </div>

        </>
    )
}
export default EstilsRanking