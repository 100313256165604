
import './login.css';
import { useState, useRef, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import socketIO from 'socket.io-client';

const Home = () => {

    useEffect(() => {
        if (localStorage.getItem('pantalla')) {
            window.location.href = '/pantalla/'+localStorage.getItem('pantalla');
        }
       socketRef.current = socketIO.connect("https://pissarra.outlius.com", {
            'reconnection': true,
            'reconnectionDelay': 500,
            'reconnectionAttempts': 10,
            'transports': ['websocket'],
            'origins': '*:*'
          });

          socketRef.current.on('pantalla-incorrecta', () => {
            toast.error('El ID de la pantalla es incorrecto', {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            });

        

          socketRef.current.on('pantalla-correcta', (data) => {
            console.log(data);
            localStorage.setItem('pantalla', data.id);
            window.location.href = '/pantalla/'+data.id;
            });

          return () => {
            socketRef.current.close();
          }

    }, []);
 
    

    const pantalla = useRef();
    const socketRef = useRef(null);
    const handleSubmit = () => {
        socketRef.current.emit('accederpantalla', pantalla.current.value);


    }
    

    return (
        <div className="screen-1">
        <img className="logo" src="/logo.png" alt="logo" />
        <div className="email">
            <label htmlFor="email">Introduce el ID de la pantalla</label>
            <div className="sec-2">
                <ion-icon name="mail-outline"></ion-icon>
                <input ref={pantalla} type="text" name="pantalla" placeholder="ID de la pantalla" />
            </div>
        </div>
        <button onClick={()=> handleSubmit()} className="login">Acceder</button>
        <div className="footer">En caso de error pongase en contacto con el administrador</div>
        <ToastContainer
            position="bottom-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
        />
    </div>
    );
    }
    export default Home;