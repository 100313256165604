

import './operadora_item.css';
const OperadoraItem = (props) => {
    return (


        <div className="mainItem" >
            <div className='itemPadre'>
                {props.contador}
            </div>
            <div className="item" style={{ "backgroundColor": props.color }}>
                <div className="boxImgRank" style={{ "position": "relative" }}>
                    <img className='imgItem' src={props.img} alt="imagen" />
                </div>
                <h2>{props.nombre}</h2>
                <p className='exp'>{props.exp}</p>
                <p className='sem'>{props.sem}</p>
                <p className='sup'>{props.sup}</p>
                <p className='t'>{props.T}</p>
            </div>
        </div>
    );
}
export default OperadoraItem;