const TopBar = () => {
    return (
        <div className="main-navbar bg-white sticky-top">
            <div className="userA">
                {localStorage.getItem('usuario')}
                <div className="logOut" onClick={() => {
                    localStorage.clear();
                    window.location.href = '/login';
                }}>
                    <i class="material-icons">logout</i>
                </div>
            </div>
        </div>

    )
}
export default TopBar;