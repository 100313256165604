import React from 'react';
import Sidebar from './sidebar';
import TopBar from './topbar';
import { useEffect, useState, useRef } from 'react';
import socketIO from "socket.io-client";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import Iframe from '../iframe';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Snapshots = () => {
    const [pantallas, setPantallas] = useState(0);
    const [selectedPantalla, setSelectedPantalla] = useState(0);
    const socketRef = useRef(null);
    const [snapshots, setSnapshots] = useState(null);
    const [fechasSnapshots, setFechasSnapshots] = useState([]);
    const [fechaSeleccionada, setFechaSeleccionada] = useState(null);
    const [date, setDate] = useState(new Date());
    const [urlCSV, setUrlCSV] = useState(null);
    const empresas = {
        1: "Integram",
        2: "Kram",
        3: "JQS",
        4: "DEV",
    };

    const onChange = (date) => {


        if (fechasSnapshots.some((fecha) => fecha.getDate() === date.getDate() && fecha.getMonth() === date.getMonth() && fecha.getFullYear() === date.getFullYear())) {

            //busca en snapshots el snapshot con la fecha seleccionada
            const snapshot = snapshots.find((snapshot) => snapshot.fecha.getDate() === date.getDate() && snapshot.fecha.getMonth() === date.getMonth() && snapshot.fecha.getFullYear() === date.getFullYear());
            //ahora hemos de pasar el snapshot.trabajadores a un JSON porque es un string


            setFechaSeleccionada(snapshot);


        } else {
            setFechaSeleccionada(null);
        }
        setDate(date);

    };

    const tileClassName = ({ date }) => {
        if (fechasSnapshots.some((fecha) => fecha.getDate() === date.getDate() && fecha.getMonth() === date.getMonth() && fecha.getFullYear() === date.getFullYear())) {
            return 'highlight';
        }
    };

    useEffect(() => {
      socketRef.current = socketIO.connect("https://pissarra.outlius.com", {
            'reconnection': true,
            'reconnectionDelay': 500,
            'reconnectionAttempts': 10,
            'transports': ['websocket'],
            'origins': '*:*'
        });

        socketRef.current.emit('getTotalPantallas');

        socketRef.current.on('totalPantallas', (data) => {
            setPantallas(data.totalPantallas);

        });
        socketRef.current.on('getSnapshot', (data) => {
         
            console.log(data);

            if (data.error) {
                toast.error(data.error);
                return;
            }
        
            if (data[0].id_empresa == selectedPantalla) {
                data.map((snapshot) => {
                    snapshot.fecha = new Date(snapshot.fecha);
                    fechasSnapshots.push(snapshot.fecha);

                })

                setSnapshots(data);


            }
        });
        socketRef.current.on('generarCSV', (data) => {
            //abrir nueva pestaña con el csv
            setUrlCSV(data.path);
        });



        return () => {
            socketRef.current.close();
        }

    }, [selectedPantalla]);

    const handleSelected = (id) => {
        if (selectedPantalla == id) {
            return;
        }

        setSelectedPantalla(id);
        setFechasSnapshots([]);
        setFechaSeleccionada(null);
        const data = {
            empresa: id,
            token: localStorage.getItem('token')
        }

        socketRef.current.emit('getSnapshot', data);

    }

    const generarCSV = () => {
        socketRef.current.emit('generarCSV', fechaSeleccionada, socketRef.current.id);
    }





    return (
        <>
            <div style={{ "min-width": "100vw", "min-height": "100vh" }}>
                <Sidebar />

                <div className='main-content p-0 col-sm-12 col-md-9 offset-md-3 col-lg-10 offset-lg-2'>
                    <TopBar />

                    <div class="page-header py-4 no-gutters row">
                        <div className="text-sm-left mb-3 text-md-left mb-sm-0 col-12 col-sm-4 fw">
                            <span className="text-uppercase page-subtitle">ADMINISTRACIÓN</span>
                            <h3 className="page-title">Snapshots</h3>
                            <br></br>
                            <div className='boxEmpresas'>
                                {pantallas &&

                                    pantallas.map((pantalla) => {
                                        return (

                                            <div
                                                onClick={() => {
                                                    handleSelected(pantalla.id)
                                                }}
                                                style={{

                                                }} className='boxEmpresa '>
                                                <p className='empresasNombre'>{empresas[pantalla.id_empresa]}</p>
                                                <p className='empresasTrabajadores'>{pantalla.id}</p>
                                                <p className='empresasNombreT'>PANTALLA</p>
                                            </div>
                                        )
                                    }
                                    )

                                }
                            </div>
                            {selectedPantalla &&
                                <div className='boxTrabajadores'>
                                    <div className='boxTrabajadores'>
                                        <h2 className='tituloGeneral'>Snapshots {empresas[selectedPantalla]}</h2>
                                        <div className='flexSnap'>
                                            <Calendar onChange={onChange} value={date} tileClassName={tileClassName} />
                                            {fechaSeleccionada &&
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th>Nombre</th>
                                                            <th>Exp. </th>
                                                            <th>Sem. *</th>
                                                            <th>Sup. </th>
                                                            <th>T </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            JSON.parse(fechaSeleccionada.trabajadores)
                                                                .filter((trabajador) => trabajador.nombre !== "")
                                                                .map((trabajador) => {
                                                                    return (
                                                                        <tr>
                                                                            <td>{trabajador.nombre}</td>
                                                                            <td>{trabajador.exp}</td>
                                                                            <td>{trabajador.sem}</td>
                                                                            <td>{trabajador.sup}</td>
                                                                            <td>{trabajador.t}</td>
                                                                        </tr>
                                                                    )
                                                                })
                                                        }

                                                    </tbody>
                                                </table>
                                            }

                                        </div>
                                        <br></br>
                                        {fechaSeleccionada &&
                                            <button onClick={generarCSV} className='btn btn-main'>Generar CSV</button>
                                        }
                                        {urlCSV &&
                                            <a href={urlCSV} download="snapshot.csv" target="_blank" className='btn btn-main'>Descargar CSV</a>

                                        }


                                    </div>
                                    <ToastContainer
                                        position="bottom-center"
                                        autoClose={5000}
                                        hideProgressBar={false}
                                        newestOnTop={false}
                                        closeOnClick
                                        rtl={false}
                                        pauseOnFocusLoss
                                        draggable
                                        pauseOnHover
                                        theme="light"
                                    />

                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Snapshots;