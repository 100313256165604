import React from 'react';

import { useEffect, useState, useRef } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import socketIO from 'socket.io-client';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';




const CrearLista = () => {
const nombreLista = useRef(null);    
const socketRef = useRef(null);

useEffect(() => {

   socketRef.current = socketIO.connect("https://pissarra.outlius.com", {
      'reconnection': true,
      'reconnectionDelay': 500,
      'reconnectionAttempts': 10,
      'transports': ['websocket'],
      'origins': '*:*'
    });

    socketRef.current.on('listaCreada', (data) => {
        window.location.replace('/dashboard/lista/'+data);
    });
    
}, []);


const generarLista = () => {
    if(nombreLista.current.value === ''){
        toast.error('El nombre de la lista no puede estar vacío');
    }else if(nombreLista.current.value.length < 3){
        toast.error('El nombre de la lista debe tener al menos 3 caracteres');
     } else{
        console.log(nombreLista.current.value);
        socketRef.current.emit('crearLista', nombreLista.current.value);
        
    }
}

    return (
        <div >
            <div className="popup">
       
                <h1 className="boxTituloPremioB">AÑADIR NUEVA LISTA</h1>
               
                <input ref={nombreLista} className="insideboxMaxiItemInput" type="text" placeholder="Nombre de la lista" />
    
                <div className="bottomPop">
                    <Link to='/dashboard'> <button className="boxMaxiButtonCancel">Cancelar</button></Link><button onClick={()=>generarLista()}className="boxMaxiButton">Guardar</button>

                </div>
            </div>
            <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>
    )   
}
export default CrearLista;