import React from 'react';
import { useParams } from 'react-router-dom';
import { useState, useEffect, useRef } from "react";
import { ReactSortable } from "react-sortablejs";
import Sidebar from './sidebar';
import TopBar from './topbar';
import { ToastContainer, toast } from 'react-toastify';
import socketIO from 'socket.io-client';
import 'react-toastify/dist/ReactToastify.css';
import { FileUploader } from "react-drag-drop-files";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { BrowserView } from 'react-device-detect';


const fileTypes = ["PNG", "GIF", "JPG"];

const EditarLista = () => {




    const [file, setFile] = useState(null);
    const [state1, setState1] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const socketRef = useRef(null);
    const tituloCapa = useRef(null);
    const yt = useRef(null);
    const segCapa = useRef(null);
    const rankingFrases = useRef(null);
    const fraseObjetivo = useRef(null);
    const numeroObjetivo = useRef(null);
    const reunionFrases = useRef(null);
    const [popup, setPopup] = useState(false);
    const [poupEdit, setPopupEdit] = useState(false);
    const [opciones, setOpciones] = useState([
        { id: 1, name: "RANKING", img: "https://cdn-icons-png.flaticon.com/512/1603/1603847.png" },
        { id: 2, name: "REUNIÓN", img: "https://cdn-icons-png.flaticon.com/512/1599/1599871.png" },
        { id: 3, name: "YOUTUBE", img: "https://cdn-icons-png.flaticon.com/512/174/174883.png" },
    ]);
    const [content, setContent] = useState("");
    const [text, setText] = useState('');
    const [text2, setText2] = useState('');

    const { id } = useParams();

    const handleChange1 = (value) => {
        setText(value);
        console.log(value);
      }
      const handleChange2 = (value) => {
        setText2(value);
        console.log(value);
      }


    const anadirCapa = () => {

        if (selectedItem.id == 1) {
            let capa = {
                tipo: selectedItem.id,
                titulo: tituloCapa.current.value,
                segundos: segCapa.current.value * 1000,
                ranking: text,
                fraseObjetivo:"",
                numeroObjetivo: 0,
            }
            setState1(prevState => {
                if (Array.isArray(prevState)) {
                    return [...prevState, capa];
                }
                else {
                    return [capa];
                }
            });

            console.log(state1);
            setPopup(false);
        }
        else if (selectedItem.id == 2) {
            let capa = {
                tipo: selectedItem.id,
                titulo: tituloCapa.current.value,
                segundos: segCapa.current.value * 1000,
                reunion: text2,
                files: file
            }
            setState1(prevState => {
                if (Array.isArray(prevState)) {
                    return [...prevState, capa];
                }
                else {
                    return [capa];
                }
            });

            console.log(state1);
            setPopup(false);
        }
        else if (selectedItem.id == 3) {
            let capa = {
                tipo: selectedItem.id,
                titulo: tituloCapa.current.value,
                yt: yt.current.value,
            }
            setState1(prevState => {
                if (Array.isArray(prevState)) {
                    return [...prevState, capa];
                }
                else {
                    return [capa];
                }
            });
            setPopup(false);
        }
    }

    const handleChange = (file) => {
        console.log(file);
        //setFile(file.target.files[0]);
        let fileData = {
            name: file.name,
            file: file,
        }
        socketRef.current.emit('upload-file', fileData);

        socketRef.current.on('file-uploaded', (response) => {

            setFile(response.path);
        });
    };

    const handleDelCapa = (item) => {
        console.log(item);
        setState1(state1.filter((e) => e !== item));
    }


    useEffect(() => {
       socketRef.current = socketIO.connect("https://pissarra.outlius.com", {
            'reconnection': true,
            'reconnectionDelay': 500,
            'reconnectionAttempts': 10,
            'transports': ['websocket'],
            'origins': '*:*'
        });
        socketRef.current.emit('getvistas', { id: id });
        socketRef.current.on('pantallaVistas', (data) => {


            setState1(JSON.parse(data[0].json));
        });

        return () => {
            socketRef.current.close();
        }

    }, []);

    const handleClick = (item) => {
        console.log(item);
        setPopup(true);
        setSelectedItem(item);

    }
    const editCapa = (item) => {
        console.log(item);

        setSelectedItem(item);
        setPopupEdit(true);

    }

    const editarLista = () => {

        //busca en state1 la capa que se quiere editar y la cambia por la nueva (selectedItem) utilizando el nombre de la capa como referencia

        let index = state1.findIndex((e) => e.titulo === selectedItem.titulo);
        state1[index] = selectedItem;
        console.log(state1);





        setPopupEdit(false);


    }



    const guardarLista = () => {

        socketRef.current.emit('guardarLista', { id: id, json: state1 }, socketRef.current.id);
     


        //window.location.replace('/dashboard/');
   
        socketRef.current.on('returnOK', (data) => {
            if (data === socketRef.current.id) {
                window.location.replace('/dashboard/');
            }
        });
     

    }


    return (
        <div style={{ "min-width": "100vw", "min-height": "100%" }}>
            <BrowserView>
            <Sidebar />
            </BrowserView>
            <div className='main-content p-0 col-sm-12 col-md-9 offset-md-3 col-lg-10 offset-lg-2'>
                <BrowserView>
                <TopBar />
                </BrowserView>

                <div class="page-header py-4 no-gutters row">
                    <div className="text-sm-left mb-3 text-md-left mb-sm-0 col-12 col-sm-4 fw">
                        <span className="text-uppercase page-subtitle">ADMINISTRACIÓN</span>
                        <h3 className="page-title">Modificando lista de reproducción {id}</h3>
                    </div>
                    <div className='marginTopBox'>
                        <div className='ListadoBox'>
                            <div className='listado1'>
                                <h2 className='text-uppercase page-subtitle'>TIME LINE</h2>
                                {state1 &&
                                    <ReactSortable delay={2} delayOnTouchOnly={true} animation={200} list={state1} setList={setState1}>
                                        {state1.map((item) => (

                                            <div className='listadoDrag' key={item.id}><span className='editText' onClick={() => editCapa(item)}>{item.titulo.length > 20 ? item.titulo.substring(0, 20) + "..." : item.titulo}</span><div><span className='timeOrden'>{item.segundos / 100} {item.segundos ? "seg" : ""}</span><span className='delCapa' onClick={() => handleDelCapa(item)}>X</span></div></div>

                                        ))}
                                    </ReactSortable>
                                }
                                {!state1 &&
                                    <div className=''><span style={{ "fontStyle": "italic" }}>No hay capas...</span></div>
                                }
                            </div>
                            <div className='listado2'>
                                {
                                    opciones.map((item) => (
                                        <div style={{ "cursor": "pointer" }} className='boxOpciones' onClick={() => handleClick(item)} key={item.name}><img height="80px" src={item.img} /><span style={{ "marginTop": "15px" }}>{item.name}</span></div>
                                    ))
                                }
                            </div>
                        </div>
                        {popup &&
                            <div className='bgPop'>
                                <div className='popup itemClicked'>
                                    <span className='closePop' onClick={() => setPopup(false)}>X</span>

                                    <div className='popupContent'>
                                        <div className='popupHeader'>
                                            <h2 className='text-uppercase page-subtitle'>{selectedItem.id == 1 ? "Ranking" : "Reunión"}</h2>
                                            <h3 className="page-title">Añadir nueva capa </h3>
                                        </div>
                                        <div className='popupBody'>
                                            <div>
                                                <h5 className='insidePopTitle'>Nombre de la capa</h5>
                                                <input ref={tituloCapa} type="text" className='form-control' />
                                            </div>
                                            {selectedItem.id != 3 &&
                                                <div>

                                                    <h5 className='insidePopTitle'>Selecciona el tiempo de duración de la capa (seg)</h5>
                                                    <input ref={segCapa} type="number" className='form-control' />
                                                </div>
                                            }
                                        </div>
                                        <div className='popupBodyType'>
                                            {selectedItem.id == 1 &&
                                                <div>
                                                   
                                                    <h5 className='insidePopTitle'>Añade las frases para el Ranking (separadas por dos ;;)</h5>
                                                    {
                                                        /*
                                                        <textarea ref={rankingFrases} className='form-control' rows="5"></textarea>

                                                        Aqui ha de ir react quill
                                                        */
                                                        <ReactQuill value={text}
                                                        onChange={handleChange1}
                                                        modules={{toolbar: [
                                                          [{ 'header': [1, 2, false] }],
                                                          ['bold', 'italic', 'underline','strike', 'blockquote'],
                                                          [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                                                          ['link', 'image'],
                                                          ['clean']
                                                        ]}}/>
                                          
                                                    }


                                                </div>
                                            }
                                            {selectedItem.id == 2 &&
                                                <div>
                                                    <h5 className='insidePopTitle'>Añade las frases para la reunión (separadas por dos ;;)</h5>
                                                    <ReactQuill value={text2}
                                                        onChange={handleChange2}
                                                        modules={{toolbar: [
                                                          [{ 'header': [1, 2, false] }],
                                                          ['bold', 'italic', 'underline','strike', 'blockquote'],
                                                          [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                                                          ['link', 'image'],
                                                          ['clean']
                                                        ]}}/>



                                                    <div style={{ "marginTop": "20px", "marginBottom": "20px" }}>
                                                        <h5 style={{ "textAlign": "left" }} className='insidePopTitle'>Añade las fotos para la reunión</h5>
                                                        <FileUploader handleChange={handleChange} name="file" types={fileTypes} />
                                                    </div>
                                                </div>
                                            }
                                            {selectedItem.id == 3 &&
                                                <div>
                                                    <h5 className='insidePopTitle'>Añade el enlance del video de YOUTUBE</h5>
                                                    <input ref={yt} className='form-control' rows="5" />
                                                </div>
                                            }
                                        </div>

                                        <div className='popupFooter'>
                                            <button onClick={() => anadirCapa()} style={{ "marginTop": "10px", "borderRadius": "5px" }} className='btn-main'>Añadir capa</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            poupEdit &&
                            <div className='bgPop'>
                                <div className='popup itemClicked'>
                                    <span className='closePop' onClick={() => setPopupEdit(false)}>X</span>

                                    <div className='popupContent'>
                                        <div className='popupHeader'>
                                            <h2 className='text-uppercase page-subtitle'>{selectedItem.tipo == 1 ? "Ranking" : "Reunión"}</h2>
                                            <h3 className="page-title">Editar capa </h3>
                                        </div>
                                        <div className='popupBody'>
                                            <div>
                                                <h5 className='insidePopTitle'>Nombre de la capa</h5>
                                                <input value={selectedItem.titulo} type="text" className='form-control' readOnly />
                                            </div>
                                            {selectedItem.tipo != 3 &&
                                                <div>

                                                    <h5 className='insidePopTitle'>Selecciona el tiempo de duración de la capa (seg)</h5>
                                                    <input onChange={(e) => setSelectedItem({ ...selectedItem, segundos: e.target.value })} value={selectedItem.segundos} type="number" className='form-control' />
                                                </div>
                                            }
                                        </div>
                                        <div className='popupBodyType'>
                                            {selectedItem.tipo == 1 &&
                                                <div>
                                                    <div className='dividerInsideBox'>
                                                    
                                                    </div>
                                                    <h5 className='insidePopTitle'>Añade las frases para el Ranking (separadas por dos ;;)</h5>
                                                    
                                                    
                                                    <ReactQuill 
                                                       onChange={(e) => setSelectedItem({ ...selectedItem, ranking: e })}
                                                    //onChange={(e) => console.log(e)}
                                                        value={selectedItem.ranking}
                                                        modules={{toolbar: [
                                                          [{ 'header': [1, 2, false] }],
                                                          ['bold', 'italic', 'underline','strike', 'blockquote'],
                                                          [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                                                          ['link', 'image'],
                                                          ['clean']
                                                        ]}}/>

                                                </div>
                                            }
                                            {selectedItem.tipo == 2 &&
                                                <div>
                                                    <h5 className='insidePopTitle'>Añade las frases para la reunión (separadas por dos ;;)</h5>
                                                  
                                                    <ReactQuill 
                                                       onChange={(e) => setSelectedItem({ ...selectedItem, reunion: e })}
                                                    //onChange={(e) => console.log(e)}
                                                        value={selectedItem.reunion}
                                                        modules={{toolbar: [
                                                          [{ 'header': [1, 2, false] }],
                                                          ['bold', 'italic', 'underline','strike', 'blockquote'],
                                                          [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                                                          ['link', 'image'],
                                                          ['clean']
                                                        ]}}/>

                                                    <div style={{ "marginTop": "20px", "marginBottom": "20px" }}>
                                                        <h5 style={{ "textAlign": "left" }} className='insidePopTitle'>Añade las fotos para la reunión</h5>
                                                        <FileUploader handleChange={handleChange} name="file" types={fileTypes} />
                                                    </div>
                                                </div>
                                            }
                                            {selectedItem.tipo == 3 &&
                                                <div>
                                                    <h5 className='insidePopTitle'>Añade el enlance del video de YOUTUBE</h5>
                                                    <input className='form-control' rows="5" />
                                                </div>
                                            }
                                        </div>

                                        <div className='popupFooter'>
                                            <button onClick={() => editarLista()} style={{ "marginTop": "10px", "borderRadius": "5px" }} className='btn-main'>Editar capa</button>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        }




                    </div>
                    <button onClick={() => guardarLista()} className='btn-flotante'>Guardar lista</button>
                </div>
            </div>
        </div >
    )
}
export default EditarLista;