const Iframe = (data) => {

    return (
        <div id="Iframe-Liason-Sheet" className="iframe-border center-block-horiz">
            <div className="responsive-wrapper responsive-wrapper-wxh-760x1200">
                <iframe src={data.data}>Cargando...</iframe>
            </div>
        </div>
    );
};
export default Iframe;
