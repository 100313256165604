import React from 'react';
import Sidebar from './sidebar';
import TopBar from './topbar';
import { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import socketIO from 'socket.io-client';
import 'react-toastify/dist/ReactToastify.css';
import { FileUploader } from "react-drag-drop-files";
import { BrowserView } from 'react-device-detect';


const PanelControl = () => {
    const socketRef = useRef(null);
    const [pantallas, setPantallas] = useState([]);
    const [totalPantallas, setTotalPantallas] = useState(0);
    const [vistas, setVistas] = useState([]);
    const tituloPremio = useRef(null);
    const subPremio = useRef(null);
    const idPremio = useRef(null);
    const [popup, setPopup] = useState(false);
    const [selectedPantalla, setSelectedPantalla] = useState(null);
    const [selectedOption, setSelectedOption] = useState(1);
    const [pantallaReinicio, setPantallaReinicio] = useState(null);
    const [listasDeReproduccion, setListasDeReproduccion] = useState([]);
    const [urlGoogleDocsDev, setUrlGoogleDocsDev] = useState('');
    const [urlGoogleDocsIntegram, setUrlGoogleDocsInntegram] = useState('');
    const [urlGoogleDocsKram, setUrlGoogleDocsKram] = useState('');
    const [urlGoogleDocsJQS, setUrlGoogleDocsJQS] = useState('');
    const [iframes, setIframes] = useState([]);
    const [tipoPremio, setTipoPremio] = useState(0);
    const fileTypes = ["MP4", "AVI", "MPG", "MKV", "MOV"];
    const [file, setFile] = useState(null);
    const [operadoras, setOperadoras] = useState([]);
    const [operadoraPremioSelected, setOperadoraPremioSelected] = useState(null);
    const empresasNombre = {
        1: 'Integram',
        2: 'Kram',
        3: 'JQS',
        4: 'DEV',

    }
    const anadirLista = (pantalla) => {
        setSelectedPantalla(pantalla);
        setPopup(true);
    }
    const guardarLista = () => {

        let json = {
            id_pantalla: selectedPantalla.id.toString(),
            id_lista: selectedOption
        }

        socketRef.current.emit('asignarLista', json);
        setPopup(false);

    }
    const handleChange = (event) => {
        console.log(event.target.value);
        setSelectedOption(event.target.value);
    }

    const handleMostrarGoogleDocs = (e, id) => {
        if (e.target.checked) {

            if (id === 4 && urlGoogleDocsDev !== '') {
                toast.success('Google Docs activado');
                socketRef.current.emit('googleDocs', { id: id, url: urlGoogleDocsDev, mostrarGoogle: true, auto: urlGoogleDocsDev.auto });
            }
            if (id === 1 && urlGoogleDocsIntegram !== '') {
                toast.success('Google Docs activado');
                socketRef.current.emit('googleDocs', { id: id, url: urlGoogleDocsIntegram, mostrarGoogle: true });
            }
            if (id === 2 && urlGoogleDocsKram !== '') {
                toast.success('Google Docs activado');
                socketRef.current.emit('googleDocs', { id: id, url: urlGoogleDocsKram, mostrarGoogle: true });
            }
            if (id === 3 && urlGoogleDocsJQS !== '') {
                toast.success('Google Docs activado');
                socketRef.current.emit('googleDocs', { id: id, url: urlGoogleDocsJQS, mostrarGoogle: true });
            }
        } else {
            toast.error('Google Docs desactivado');
            socketRef.current.emit('googleDocs', { id: id, mostrarGoogle: false });
        }
    }


    const handleMostrarPremio = (e) => {
        if (idPremio.current.value === '') {
            toast.error('Debes seleccionar una pantalla');
            return;
        }


        if (tipoPremio == 0) {
            if (e.target.checked) {
                toast.success('Premio activado');
            } else {
                toast.error('Premio desactivado');
            }

            let json = {
                mostrarPremio: e.target.checked,
                titulo: tituloPremio.current.value,
                subtitulo: subPremio.current.value,
                id: idPremio.current.value
            }

            socketRef.current.emit('premio', json);
        } else if (tipoPremio == 1) {

            //si operadoraPremioSelected o file es null o undefined no se puede mostrar el premio
            if (operadoraPremioSelected == null || file == null || file == undefined) {
                toast.error('Debes seleccionar una operadora y un video');
                return;
            }

            let json = {
                tipoPremio: tipoPremio,
                mostrarPremio: e.target.checked,
                operadora: operadoraPremioSelected,
                file: file,
                id: idPremio.current.value
            }

            socketRef.current.emit('premio', json);


        }
    }
    const reiniciarPantalla = () => {
        socketRef.current.emit('reiniciarPantalla', pantallaReinicio);


    }
    const elminarCapa = (id) => {
        socketRef.current.emit('eliminarCapa', id);
    }

    /*
       const handleChange = (file) => {
            console.log(file);
            //setFile(file.target.files[0]);
            let fileData = {
                name: file.name,
                file: file,
            }
            socketRef.current.emit('upload-file', fileData);
    
            socketRef.current.on('file-uploaded', (response) => {
    
                setFile(response.path);
            });
        };
        */


    useEffect(() => {

        socketRef.current = socketIO.connect("https://pissarra.outlius.com", {
            'reconnection': true,
            'reconnectionDelay': 500,
            'reconnectionAttempts': 10,
            'transports': ['websocket'],
            'origins': '*:*'
        });



        socketRef.current.on('error-auth', (data) => {
            toast.error("Error de autenticación", {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                onClose: () => {
                    localStorage.removeItem('token');
                    window.location.href = '/login';
                }
            });

        });




        // socket.on('pantalla', (data) => {
        socketRef.current.on('frases', (data) => {

        });

        socketRef.current.emit('comprobarToken', { token: localStorage.getItem('token') });

        socketRef.current.emit('getPantallas', {});
        socketRef.current.emit('getvistas', {});
        socketRef.current.emit('getIframes', {});
        socketRef.current.emit('getAllOperadoras', {});

        socketRef.current.on('setAllOperadoras', (data) => {
            //guarda en el estado todas las operadoras que el nombre no este vacio
            data = data.filter((operadora) => operadora.nombre !== '');
            setOperadoras(data);

        });

        socketRef.current.on('pantallas', (data) => {
            setPantallas(data.pantallasConectadas);
            setTotalPantallas(data.totalPantallas);
        });

        socketRef.current.on('pantallaVistas', (data) => {
            setVistas(data);
        });
        socketRef.current.on('reiniciarPantalla', (data) => {

            if (data.error) {
                toast.error('Error al reiniciar');
            } else {
                toast.success('Pantalla reiniciada');
            }

        });

        socketRef.current.on('getPlaylist', (data) => {
            setListasDeReproduccion(data);
        });

        socketRef.current.on('capaEliminada', (data) => {
            if (data.error) {
                toast.error('Error al eliminar capa');
            } else {
                toast.success('Capa eliminada');
                socketRef.current.emit('getPantallas', {});
                socketRef.current.emit('getvistas', {});

            }
        });
        socketRef.current.on('updateLista', (data) => {
            getListasInfo();
        });
        socketRef.current.on('setIframes', (data) => {
            data.map((iframe) => {
                if (iframe.id_empresa === 1) {
                    setUrlGoogleDocsInntegram({
                        urlGoogleDocs: iframe.url,
                        auto: iframe.auto
                    }
                    );
                }
                if (iframe.id_empresa === 2) {
                    setUrlGoogleDocsKram({
                        urlGoogleDocs: iframe.url,
                        auto: iframe.auto
                    });
                }
                if (iframe.id_empresa === 3) {
                    setUrlGoogleDocsJQS(
                        {
                            urlGoogleDocs: iframe.url,
                            auto: iframe.auto
                        });
                }
                if (iframe.id_empresa === 4) {
                    setUrlGoogleDocsDev({
                        urlGoogleDocs: iframe.url,
                        auto: iframe.auto
                    });
                }


            })

            setIframes(data);
        });

        getListasInfo();
        return () => {
            socketRef.current.close();
        }

    }, []);


    const handleOperadora = (e) => {
        console.log(e.target.value);

        //busca en operadoras la operadora que tenga el id seleccionado y guarda la operadora en el estado
        let operadora = operadoras.find((operadora) => operadora.id === parseInt(e.target.value));
        setOperadoraPremioSelected(operadora);
        idPremio.current.value = operadora.id_empresa;
    }


    const getListasInfo = () => {
        socketRef.current.emit('getPlaylist', {});

    }

    const handleTipoPremio = (e) => {
        setTipoPremio(e.target.value);
    }
    const subirFelicitacion = (file) => {

        //setFile(file.target.files[0]);
        let fileData = {
            name: file.name,
            file: file,
        }
        setFile(fileData);
    }

    return (

        <div style={{ "min-width": "100vw", height: "100%" }}>
            <BrowserView>
                <Sidebar />
            </BrowserView>

            <div className='main-content p-0 col-xs-12 col-sm-12 col-md-9 offset-md-3 col-lg-10 offset-lg-2'>
                <BrowserView>
                    <TopBar />
                </BrowserView>

                <div class="page-header py-4 no-gutters row">
                    <div className="text-sm-left mb-3 text-md-left mb-sm-0 col-12 col-sm-4 fw">
                        <span className="text-uppercase page-subtitle">ADMINISTRACIÓN</span>
                        <h3 className="page-title">Panel de Control</h3>
                    </div>
                    <div className='boxMiniContainer'>
                        <div className='boxMini'>
                            <p onClick={() => getListasInfo()} className='boxMiniTitulo'>{pantallas && pantallas.length ? pantallas.length : 0}</p>
                            <div className='mauto'>
                                <h2 className='boxMiniSubtitulo'>Pantallas Conectadas</h2>
                                <p className='boxMiniSubtitulosub'>Este es el total de pantallas operativas en tiempo real</p>
                            </div>

                        </div>
                        <div className='boxMini'>
                            <div className='centerMiniBox'>
                                <p className='boxMiniTitulo'><input placeholder="0" onChange={(e) => setPantallaReinicio(e.target.value)} ></input></p>
                                <button onClick={() => reiniciarPantalla()} className='btn-main w100'>REINICIAR</button>
                            </div>
                            <div className='mauto'>
                                <h2 className='boxMiniSubtitulo'>Reiniciar Pantalla</h2>
                                <p className='boxMiniSubtitulosub'>Escribe el número de pantalla para reiniciar-la, incluso si no aparece operativa</p>
                            </div>

                        </div>
                        <div className='boxMini2'>
                            <div>
                                <h2 className='boxMiniSubtitulo'>Google docs</h2>
                                <div className='subGdocs'>
                                    <div className='flexCenter'>
                                    <p className='empresaInsideMaxi centerTxt'>Integram</p>
                                    <input checked={urlGoogleDocsIntegram.auto} type='checkbox' onChange={(e) => setUrlGoogleDocsInntegram({ ...urlGoogleDocsIntegram, auto: e.target.checked ? 1 : 0 })}></input>
                                    </div>

                                    <input placeholder='URL google docs ' value={urlGoogleDocsIntegram.urlGoogleDocs} onChange={(e) => setUrlGoogleDocsInntegram({ ...urlGoogleDocsIntegram, urlGoogleDocs: e.target.value })}></input>
                                    <div className=" flexCenter form-check form-switch">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="flexSwitchCheckDefault"
                                            onChange={(e) => handleMostrarGoogleDocs(e, 1)}

                                        />
                                    </div>
                                </div>
                                <div className='subGdocs'>
                                    <div className='flexCenter'>
                                    <p className='empresaInsideMaxi centerTxt'>Kram</p>
                                    <input checked={urlGoogleDocsKram.auto} type='checkbox' onChange={(e) => setUrlGoogleDocsKram({ ...urlGoogleDocsKram, auto: e.target.checked ? 1 : 0 })}></input>
                                    </div>
                                    <input placeholder='URL google docs ' value={urlGoogleDocsKram.urlGoogleDocs } onChange={(e) => setUrlGoogleDocsKram({ ...urlGoogleDocsKram, urlGoogleDocs: e.target.value })}></input>
                                    <div className=" flexCenter form-check form-switch">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="flexSwitchCheckDefault"
                                            onChange={(e) => handleMostrarGoogleDocs(e, 2)}

                                        />
                                    </div>
                                </div>
                                <div className='subGdocs'>
                                    <div className='flexCenter'>
                                        <p className='empresaInsideMaxi centerTxt'>JQS</p>
                                        <input checked={urlGoogleDocsJQS.auto} type='checkbox' onChange={(e) => setUrlGoogleDocsJQS({ ...urlGoogleDocsJQS, auto: e.target.checked ? 1 : 0 })}></input>
                                    </div>
                                    <input placeholder='URL google docs' value={urlGoogleDocsJQS.urlGoogleDocs} onChange={(e) => setUrlGoogleDocsDev({ ...urlGoogleDocsDev, urlGoogleDocs: e.target.value })}></input>
                                    <div className=" flexCenter form-check form-switch">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="flexSwitchCheckDefault"
                                            onChange={(e) => handleMostrarGoogleDocs(e, 3)}

                                        />
                                    </div>
                                </div>
                                <div className='subGdocs'>
                                    <div className='flexCenter'>
                                        <p className='empresaInsideMaxi centerTxt'>DEV</p>
                                        <input checked={urlGoogleDocsDev.auto} type='checkbox' onChange={(e) => setUrlGoogleDocsDev({ ...urlGoogleDocsDev, auto: e.target.checked ? 1 : 0 })}></input>
                                    </div>
                                    <input placeholder='URL google docs ' value={urlGoogleDocsDev.urlGoogleDocs} onChange={(e) => setUrlGoogleDocsDev({ ...urlGoogleDocsDev, urlGoogleDocs: e.target.value })}></input>
                                    <div className=" flexCenter form-check form-switch">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="flexSwitchCheckDefault"
                                            onChange={(e) => { handleMostrarGoogleDocs(e, 4) }}

                                        />
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>

                    <div className='boxMaxiContainer'>

                        { /*
                      
     
                        <div className='boxMaxi'>
                            <button  onClick={()=>getInfo()} className='boxMaxiButton'>+ AÑADIR PANTALLA</button>
                            {
                                //haz un bucle con el total de pantallas

                                pantallas.map((pantalla, index) => {
                                    const pantallaIsConnected = pantallas.some(
                                        pantallaConectada => pantallaConectada.id === pantalla.id
                                    );
                                    return (
                                        <div onClick={() => anadirLista(pantalla)} className='insideboxMaxiItem'>
                                            <p className='insideboxMaxiItemTitulo'>{pantalla.id} <label className='empresaInsideMaxi'>{empresasNombre[pantalla.id_empresa]}</label></p>
                                            <div className='insideboxMaxiItemSub'>
                                                <p className='insideboxMaxiItemSubTitulo'>{pantallaIsConnected ? 'Conectada' : 'Desconectada'}</p>
                                            </div>

                                        </div>
                                    )
                                })


                            }
                            


                        </div>

                        */  }


                        <div className='boxMaxi'>
                            {
                                totalPantallas &&
                                totalPantallas.map((pantallaB, index) => {
                                    const pantallaIsConnected = pantallas.some(
                                        pantallaConectada => pantallaConectada.id == pantallaB.id
                                    );
                                    return (
                                        <div onClick={() => anadirLista(pantallaB)} className='insideboxMaxiItem'>
                                            <p className='insideboxMaxiItemTitulo'><label className='empresaInsideMaxi'>{empresasNombre[pantallaB.id_empresa]}</label></p>
                                            <div className='insideboxMaxiItemSub'>
                                                <p className={pantallaIsConnected ? 'insideboxMaxiItemSubTitulo selectedPantallaB' : 'insideboxMaxiItemSubTitulo'}>
                                                    <span className='stylePlay'>
                                                        {
                                                            //busca dentro de las vistas si hay alguna que tenga el id de la pantalla
                                                            listasDeReproduccion.some(lista => lista.id_empresa == pantallaB.id) ?
                                                                listasDeReproduccion.find(lista => lista.id_empresa == pantallaB.id).nombre
                                                                :
                                                                'Sin lista'


                                                        }
                                                    </span>
                                                    <div class="d-inline-block item-icon-wrapper">
                                                        <i class="material-icons">wifi</i>
                                                    </div>
                                                </p>
                                            </div>

                                        </div>
                                    )
                                }
                                )
                            }

                        </div>


                        <div className='boxMaxi'>
                            <Link to='/dashboard/listas/'>
                                <button className='boxMaxiButton'>+ AÑADIR LISTA DE REPORDUCCIÓN</button>
                            </Link>
                            {
                                vistas.map((vista, index) => {
                                    return (

                                        <div className='insideboxMaxiItem'>
                                            <Link style={{ "textDecoration": "none", "color": "black" }} to={`/dashboard/lista/${vista.id}`}>
                                                <p className='insideboxMaxiItemTitulo'>{vista.nombre}</p>
                                            </Link>
                                            <div className='insideboxMaxiItemSub2'>
                                                <div onClick={() => { elminarCapa(vista) }} className="d-inline-block item-icon-wrapper delCapa">
                                                    <i className="material-icons">delete</i>
                                                </div>
                                            </div>

                                        </div>

                                    )
                                })
                            }
                        </div>
                        <div className='boxMaxi'>
                            <div>
                                <select className='tipusPremi' onChange={(e) => handleTipoPremio(e)}>
                                    <option value='0'>Estandar</option>
                                    <option value='1'>Personalizado</option>
                                </select>

                            </div>
                            <div className='boxPremio'>

                                {tipoPremio == 0 ?
                                    <div>
                                        <h3 className='boxTituloPremio'>TITULO DEL TEXTO DE PREMIO</h3>
                                        <input ref={tituloPremio} placeholder='FELICIDADES {NOMBRE} {APELLIDO}'></input>

                                        <h3 className='marginTop boxSubTituloPremio'>subTITULO DEL TEXTO DE PREMIO</h3>
                                        <input ref={subPremio} placeholder='POR ALCANZAR LAS 5 VENTAS'></input>
                                    </div>
                                    :
                                    <div>
                                        <h3 className='boxTituloPremio'>SUBIR VIDEO DE FELICITACIÓN</h3>
                                        <br></br>
                                        <FileUploader handleChange={subirFelicitacion} name="file" types={fileTypes} />
                                        <span className='minVideoTxt'>{file && "Video a mostrar: " + file.name}</span>

                                        <p className='operadoraListTxt'>Seleciona la operadora</p>

                                        <select className='tipusPremi' style={{ width: "100%" }}
                                            onChange={(e) => handleOperadora(e)}
                                        >
                                            {
                                                operadoras.map((operadora, index) => {
                                                    return (
                                                        <option value={operadora.id}>{operadora.nombre}
                                                            {operadora.id_empresa == 1 ? "(Integram)" : operadora.id_empresa == 2 ? "(Jqs)" : operadora.id_empresa == 3 ? "(Kram)" : "(DEV)"}

                                                        </option>
                                                    )
                                                })

                                            }
                                        </select>



                                        {
                                            //https://pissarra.outlius.com/uploads/operadores/Nuria.png
                                        }

                                    </div>
                                }

                            </div>
                            <div className='boxPremioDos'>
                                <div className='flexCenter'>
                                    <input className='inputPantalla' ref={idPremio} placeholder='ID'></input>
                                </div>
                                <div></div>
                                <div className='flexCenter'>
                                    <div>
                                        <h3 className='tituloGeneralxs'>MOSTRAR PREMIO</h3>

                                        <div className=" flexCenter form-check form-switch">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="flexSwitchCheckDefault"
                                                onChange={(e) => handleMostrarPremio(e)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {popup &&
                            <div className='bgPop'>
                                <div className='popup itemClicked'>
                                    <span className='closePop' onClick={() => setPopup(false)}>X</span>

                                    <div className='popupContent'>
                                        <div className='popupHeader'>
                                            <h2 className='text-uppercase page-subtitle'></h2>
                                            <h3 className="page-title">Lista a la pantalla 1 </h3>
                                        </div>
                                        <div className='popupBody'>
                                            <div>
                                                <h5 className='insidePopTitle'>Selecciona lista</h5>
                                                <select onClick={handleChange}>
                                                    {
                                                        //haz un bucle con el total de pantallas en un desplegable select


                                                        vistas.map((vista, index) => {
                                                            return (

                                                                <option value={vista.id}>{vista.nombre}</option>


                                                            )
                                                        })

                                                    }
                                                </select>
                                            </div>

                                        </div>



                                        <div className='popupFooter'>
                                            <button onClick={guardarLista} style={{ "marginTop": "10px", "borderRadius": "5px" }} className='btn-main'>Seleccionar lista</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <ToastContainer
                    position="bottom-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />
            </div>
        </div>
    );
}
export default PanelControl;
